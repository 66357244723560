/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "tailwind.macro"
import { StaticQuery, graphql } from "gatsby"
import LinkTo from "../components/LinkTo"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AppWrapper from "../views/AppWrapper"
import Content, { Inner } from "../elements/Content"
import Hero from "../views/Hero"
import { BigTitleLight, H2, Strong, P, PLight } from "../elements/Text"
import Button from "../elements/Button"

const isBrowser = typeof window !== "undefined"

function tagFromUrl() {
  if (!isBrowser) {
    return null
  }

  return Object.fromEntries(
    new URL(window.location.href).searchParams.entries() // eslint-disable-line no-undef
  ).tag
}

const SectionWrapper = styled(Inner)``

const SectionContainer = styled.div`
  ${tw``}
`

const PostTitle = styled.span`
  ${tw`block mt-4`};
`

const SectionHeader = styled(H2)`
  ${tw`mb-1`};
`

const Separator = styled.span`
  ${tw`w-full bg-grey-light h-px mx-auto my-6 block`};
`

const SectionContents = styled.div`
  ${tw`flex flex-col`};
`

const Title = styled(P)`
  ${tw`mt-0 mb-0 text-grey-dark`};
`

const TagContainer = styled.span`
  ${tw`text-white px-2 py-1`};
  ${props =>
    props.selected
      ? tw`bg-pink hover:bg-grey-dark`
      : tw`bg-grey-dark hover:bg-pink`};
`

const TagLink = styled(LinkTo)`
  ${tw`text-white hover:no-underline`};
`

const Tag = ({ tag }) => {
  let href = `/blog?tag=${tag}`
  let selected = false

  if (isBrowser) {
    if (tag === tagFromUrl()) {
      selected = true
      href = "/blog"
    }
  }

  return (
    <TagLink href={href}>
      <TagContainer style={{ marginRight: "9px" }} selected={selected}>
        {tag}
      </TagContainer>
    </TagLink>
  )
}

Tag.propTypes = {
  tag: PropTypes.string.isRequired,
}

const Section = ({ children, ...props }) => {
  const { title, author, slug, readingTime, keywords } = props

  return (
    <SectionContainer>
      <SectionHeader>
        <LinkTo href={slug}>
          <PostTitle>{title}</PostTitle>
        </LinkTo>
      </SectionHeader>
      <Title>
        By <Strong>{author}</Strong> • {readingTime} •{" "}
        {keywords.map(k => (
          <Tag tag={k} />
        ))}
      </Title>
      <SectionContents>{children}</SectionContents>
      <Separator />
    </SectionContainer>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  readingTime: PropTypes.string.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  date: PropTypes.string.isRequired,
}

const BlogPage = () => (
  <StaticQuery
    query={graphql`
      query ListBlogQuery {
        allMarkdownRemark(
          filter: {
            fileAbsolutePath: { regex: "/(blog)/" }
            frontmatter: { hidden: { ne: true } }
          }
          sort: { order: DESC, fields: [frontmatter___date] }
        ) {
          edges {
            node {
              fields {
                slug
                readingTime {
                  text
                }
              }
              frontmatter {
                date(formatString: "MMMM Do YYYY")
                title
                author
                short_description
                keywords
                hidden
              }
            }
          }
        }
      }
    `}
    render={data => {
      const selectedTag = tagFromUrl()

      return (
        <Layout>
          <SEO title="The Featurist Blog" />
          <AppWrapper>
            <Hero isDark isBlog style={{ zIndex: 20 }}>
              <BigTitleLight>Blog</BigTitleLight>
              <PLight />
            </Hero>
            <Content>
              <SectionWrapper condensed>
                {data.allMarkdownRemark.edges
                  .map(({ node }, i) => {
                    if (
                      selectedTag &&
                      !node.frontmatter.keywords.includes(selectedTag)
                    ) {
                      return
                    }
                    return (
                      <Section
                        key={`blog-post-${i}`}
                        title={node.frontmatter.title}
                        author={node.frontmatter.author}
                        date={node.frontmatter.date}
                        slug={node.fields.slug}
                        keywords={node.frontmatter.keywords}
                        readingTime={node.fields.readingTime.text}
                      >
                        <P>{node.frontmatter.short_description}</P>
                        <LinkTo href={node.fields.slug}>
                          <Button>Read More</Button>
                        </LinkTo>
                      </Section>
                    )
                  })
                  .filter(Boolean)}
              </SectionWrapper>
            </Content>
          </AppWrapper>
        </Layout>
      )
    }}
  />
)

export default BlogPage
